import i18n from "@/i18n";

export {
    copyUnitAddress2Clipboard,
    getUnitMarkerIconHTML,
}
function copyUnitAddress2Clipboard(lmsg) {
    let data = [];
    let address = lmsg?.address
    let latlng = Object.values((lmsg?.latlng || {})).join(',')
    if(address) {
        data.push(i18n.global.t('text.Address') + ':\n' + address)
    }
    if(latlng) {
        data.push(i18n.global.t('text.Coordinates') + ':\n' + latlng)
        data.push(i18n.global.t('text.Google Link') + ':\n' + 'https://www.google.com/maps/search/?api=1&query=' + latlng)
    }
    data = data.filter(v => v).join('\n')
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(data)
    } else
    if (window.clipboardData) {
        window.clipboardData.setData("Text", data)
        return Promise.resolve(true)
    }
    return Promise.reject(null)
}

function getUnitMarkerIconHTML(params) {

    return '<div class="unit-marker ' + (params.icon_has_halo ? 'unit-marker-has-halo' : '') + '">' +
        '<div class="unit-marker-svg-icon ' + params.statusClass + '" style="' + params.styleIcon + '">' +
        // '<img class="svg-icon-unit-marker" src="'+img_src+'" sty-le="color:'+icon_color+'" style="'+style+'" width="50px" height="50px" >' +
        '<svg class="svg-icon-unit-marker" xmlns="http://www.w3.org/2000/svg">' +
        '<use xlink:href="' + params.img_src + '"/>' +
        '</svg>' +
        '</div>' +
        // '<img class="unit-marker-icon" src="'+unit_icon+'" />'+
        // '<img class="unit-ignition-icon" src="/img/ignition_icon.png" />'+
        // '<img class="unit-moving-icon" src="/img/moving_icon.png" />'+
        // '<img class="unit-status-icon" src="/img/unit-icon-status-motion.png" style="transform: rotateZ('+lmsg?.course+'deg)'+';"/>'+
        //                                 // /img/unit-icon-status-parking-more-5-minutes.png
        //                                 // /img/unit-icon-status-parking-more-15-minutes.png
        '<div class="leaflet-label unit-marker-label" style="' + params.styleName + '">' + params.name + '</div>' +
        '</div>';
}